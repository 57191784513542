import React, { useContext, useLayoutEffect, useState } from 'react'
import { graphql, Link } from 'gatsby';
import { css, Global } from '@emotion/react';
import { ThemeContext } from '../components/theme-provider';

export default function FourZeroFour({ data }) {
  const theme = useContext(ThemeContext);

  useLayoutEffect(() => {
    theme.setGrayFooter(false);
  }, []);

  return (
    <div
      css={css`
        background-color: var(--primary);
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
        `}
      >
        <h1
          className="type--70"
          css={css`
            grid-column: span 10;
            color: var(--black);
            padding: var(--margin) var(--margin) 0;
            margin-bottom: var(--bottomMargin);

            @media (max-width: 1080px) {
              grid-column: span 12;
            }

            a {
              color: var(--black);
              text-decoration: none;

              &:hover {
                color: var(--white);
              }
            }
          `}
        ><Link to="/">The Substation</Link>/&#x200B;404/</h1>
        <div
          className="type--45"
          css={css`
            grid-column: span 12;
            padding: var(--margin) var(--margin) var(--bigMargin);
          `}
        >Sorry, this page can't be found.</div>
      </div>
    </div>
  );
}
